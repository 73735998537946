import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Container } from './profilePic.css';

const ProfilePic = ({ items }) => {
  return (
    <Container>
      <Img
        fluid={items ? items[0].image.childImageSharp.fluid : {}}
        alt="toto"
      />
    </Container>
  );
};

ProfilePic.propTypes = {
  items: PropTypes.array.isRequired,
};

export default ProfilePic;

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Container } from '../components/specialCSS/specialCSS.css';
import Layout from 'components/layout';
import Gallery from 'components/gallery';
import Box from 'components/box';
import Head from 'components/head';
import ProfilePic from '../components/profile-pic/profilePic';

const About = ({ data, image }) => (
  <Layout>
    <Head pageTitle={data.aboutJson.title} />
    <Container>
      <Box>
        <div
          dangerouslySetInnerHTML={{
            __html: data.aboutJson.content.childMarkdownRemark.html,
          }}
        />
      </Box>
      <ProfilePic items={data.aboutJson.gallery} />
    </Container>
  </Layout>
);

About.propTypes = {
  data: PropTypes.object.isRequired,
};

export default About;

export const query = graphql`
  query AboutQuery {
    aboutJson {
      title
      gallery {
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;

import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  margin: 0 4rem;
  min-width: 500px;
  ${MEDIA.TABLET`
  min-width: 300px;
  `};
  ${MEDIA.PHONE`
  min-width: 200px;
  `};
`;
